import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";
import dynamic from "next/dynamic";

// Components
const Footer = dynamic(() => import("../interface/footer"));
const Navbar = dynamic(() => import("../interface/navbar"));

function General({ children: page, footerProps, headerProps }) {
  return (
    <>
      <div className={`w-full flex flex-col bg-white`}>
        <Navbar className={`z-10`} {...headerProps} />
        <AnimateSharedLayout>
          <AnimatePresence>
            <motion.main
              className={`z-0 min-h-screen`}
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              exit={{
                opacity: 0,
              }}
              transition={{
                duration: 0,
                opacity: {
                  duration: 0.3,
                },
              }}
              layout
            >
              {page}
            </motion.main>
          </AnimatePresence>
        </AnimateSharedLayout>
        <Footer {...footerProps} />
      </div>
    </>
  );
}

export default General;
